//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-928:_4748,_9524,_6140,_2608,_6184,_2436,_156,_6948;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-928')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-928', "_4748,_9524,_6140,_2608,_6184,_2436,_156,_6948");
        }
      }catch (ex) {
        console.error(ex);
      }